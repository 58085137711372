<template>
  <el-menu :default-openeds="opens" style="min-height: 100%; overflow-x: hidden" background-color="rgb(48, 65, 86)"
    text-color="#fff" active-text-color="#ffd04b" :collapse-transition="false" :collapse="isCollapse" router>
    <div style="height: 60px; line-height: 60px; text-align: center">
      <img src="../assets/logo.png" alt="" style="width: 20px; position: relative; top: 5px;">
      <b style="color: white; margin-left: 5px" v-show="logoTextShow">后台管理系统</b>
    </div>
    <div v-for="item in menus" :key="item.id">
      <div v-if="item.path">
        <el-menu-item :index="item.path">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
      <div v-else>
        <el-submenu :index="item.id + ''">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <div v-for="subItem in item.children" :key="subItem.id">
            <el-menu-item :index="subItem.path">
              <i :class="subItem.icon"></i>
              <span slot="title">{{ subItem.name }}</span>
            </el-menu-item>
          </div>
        </el-submenu>
      </div>
    </div>
  </el-menu>
</template>

<script>
export default {
  name: "Aside",
  props: {
    isCollapse: Boolean,
    logoTextShow: Boolean
  },
  data() {
    return {
      menus: [{
        "id": 10,
        "name": "主页",
        "path": "/home",
        "icon": "el-icon-house",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "Home",
        "sortNum": "0"
      },
      {
        "id": 12,
        "name": "文件类型管理",
        "path": "/MediaType",
        "icon": "el-icon-menu",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "MediaType",
        "sortNum": "998"
      },
      {
        "id": 13,
        "name": "文件管理",
        "path": "/File",
        "icon": "el-icon-menu",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "File",
        "sortNum": "999"
      },
      {
        "id": 14,
        "name": "banner管理",
        "path": "/Banner",
        "icon": "el-icon-menu",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "File",
        "sortNum": "999"
      }
      ],
      opens: [{
        "id": 10,
        "name": "主页",
        "path": "/home",
        "icon": "el-icon-house",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "Home",
        "sortNum": "0"
      },
      {
        "id": 12,
        "name": "文件类型管理",
        "path": "/MediaType",
        "icon": "el-icon-menu",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "MediaType",
        "sortNum": "998"
      },
      {
        "id": 13,
        "name": "文件管理",
        "path": "/File",
        "icon": "el-icon-menu",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "File",
        "sortNum": "999"
      },
      {
        "id": 14,
        "name": "banner管理",
        "path": "/Banner",
        "icon": "el-icon-menu",
        "description": null,
        "children": [],
        "pid": null,
        "pagePath": "File",
        "sortNum": "999"
      }
      ].map(v => v.id + '')
    }
  },
}
</script>

<style scoped>
.el-menu-item.is-active {
  background-color: rgb(38, 52, 69) !important;
}

.el-menu-item:hover {
  background-color: rgb(38, 52, 69) !important;
}

.el-submenu__title:hover {
  background-color: rgb(38, 52, 69) !important;
}

/*解决收缩菜单文字不消失问题*/
.el-menu--collapse span {
  visibility: hidden;
}
</style>
